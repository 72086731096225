<template>
  <div class="JCI_Projects">
    <!-- 页面 header 通屏大图 -->
    <FullBanner :bannerURL="$route.meta.img" :moduleName="theme">
      <template v-slot:customFill>
        <iq-card class="iq-card">
          <section class="container top_style">
            <div class="tab-btn-my" style="padding-top:16px">
              <tab-nav :tabs="true" id="myTab-two" class="tabsWidthScroll">
                <tab-nav-items :active="active == 'gallery'" href="#gallery" title="Project Gallery"
                  @click.native="(page = 1,$store.commit('Public/CHANGE_LIST', 'gallery'))" />
                <tab-nav-items :active="active == 'legacy'" href="#legacy" title="Legacy Projects"
                  @click.native="(page = 1,$store.commit('Public/CHANGE_LIST', 'legacy'))" />
                <tab-nav-items :active="active == 'explore'" href="#explore" title="JCI RISE"
                  @click.native="(($store.commit('Public/CHANGE_LIST', 'explore')),getProjectRise())" />
              </tab-nav>
            </div>
            <div class="option_btn">
              <div class="mr-3 to_my_training" @click="openDialog">Create a new Project</div>
              <div class="to_my_training" @click="($store.commit('Public/CHANGE_PAGE', 1)),$router.push('/my-project')">
                <span>My Projects</span>
              </div>
            </div>
            <el-dropdown trigger="click" class="phone_btn">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div class="mr-3 to_my_training" @click="openDialog">Create a new Project</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="to_my_training" @click="$router.push('/my-project')"><span>My Projects</span></div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </section>
        </iq-card>
      </template>
    </FullBanner>

    <tab-content>

      <tab-content-item :active="active == 'gallery'" aria-labelled-by="prerecording-tab-two">
        <div class="container">
          <section class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <el-input clearable @clear="getProjectList" @keyup.enter.native="getProjectList" class="w-100"
                placeholder="Search Project" v-model="searchParams.project_name" suffix-icon="el-icon-search">
              </el-input>
              <div class="ml-3">
                <i class="el-icon-caret-top cursor-pointer" style="font-size: 30px;color:#fff" v-if="!showMore"
                  @click="showMore=1"></i>
                <i class="el-icon-caret-bottom cursor-pointer" style="font-size: 30px;color:#fff" v-if="showMore"
                  @click="showMore=0"></i>
              </div>
            </div>
          </section>
          <section class='slide-down-content'>
            <el-row :gutter="10">
              <el-col :xs="24" :sm="8" class="mb-3">
                <el-date-picker style="width: 100%" clearable v-model="dateRange" @change="getProjectList"
                  type="datetimerange" range-separator="~" start-placeholder="Start" end-placeholder="End"
                  value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-col>
              <el-col :xs="24" :sm="4" class="mb-3">
                <el-date-picker style="width: 100%" clearable v-model="searchParams.year" @change="getProjectList"
                  type="year" placeholder="Year" value-format="yyyy">
                </el-date-picker>
              </el-col>
              <el-col :xs="24" :sm="7" class="mb-3">
                <el-select clearable @clear="getProjectList" v-model="searchParams.area_category"
                  @change="getProjectList" placeholder="Areas of Opportunity Category" style="width: 100%;">
                  <el-option v-for="(v,i) in opportunityList" :value="v.value" :key="v.value" :label="v.label">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="5" class="mb-3">
                <el-select clearable @clear="getProjectList" v-model="searchParams.project_category"
                  @change="getProjectList" placeholder="Rise Pillars Category" style="width: 100%;">
                  <el-option v-for="(v,i) in categoryList" :value="v.value" :key="v.value" :label="v.label">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <OrganizationProject @getOrganizationParams="getOrganizationParams" />
              <el-col :xs="24" :sm="5" class="mb-3">
                <el-select clearable @clear="getProjectList" v-model="searchParams.is_has_project"
                  @change="getProjectList" style="width: 100%;" placeholder="RISE Project">
                  <el-option :value="1" label="Yes"></el-option>
                  <el-option :value="0" label="No"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </section>
          <b-row v-if="loading">
            <b-col v-for="(item, index) in 12" :key="index" md="6" lg="4">
              <el-skeleton animated>
                <template slot="template">
                  <iq-card body-class="p-0" style="border-radius: 5px;overflow: hidden;">
                    <el-skeleton-item variant="image" style="width: 100%; height: 140px;" />
                    <el-skeleton-item variant="text" style="width: 220px;height: 30px;margin: 16px 10px 8px;" />
                    <el-skeleton-item variant="text" style="width: 150px;height: 28.63px;margin: 6px 10px 13px" />
                  </iq-card>
                </template>
              </el-skeleton>
            </b-col>
          </b-row>
          <section class="Offline" v-if="projectList.length&&!loading">
            <b-row>
              <b-col v-for="(item, index) in projectList" :key="index" md="6" lg="4" class="cursor-pointer"
                @click="$router.push(`/projects-detail?id=${item.id}&page=${page}`)">
                <Prerecording :cardInfo="item" />
              </b-col>
            </b-row>
          </section>
          <section v-if="!projectList.length" class="d-flex justify-content-center"
            style="background:#fff;padding: 60px;padding-bottom: 80px;">
            <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" v-if="!loading" />
          </section>
        </div>
        <div v-if="projectList.length" class="d-flex justify-content-end container">
          <el-pagination :pager-count="5" background layout="prev, pager, next" :page-size="size"
            @current-change="handleCurrentChange" :current-page.sync="page" :total="total" hide-on-single-page>
          </el-pagination>
        </div>
      </tab-content-item>
      <tab-content-item :active="active == 'legacy'" aria-labelled-by="prerecording-tab-two">
        <div class="container">
          <section class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <el-input clearable @clear="getProjectList" @keyup.enter.native="getProjectList" class="w-100"
                placeholder="Search Project" v-model="searchParams.project_name" suffix-icon="el-icon-search">
              </el-input>
              <div class="ml-3">
                <i class="el-icon-caret-top cursor-pointer" style="font-size: 30px;color:#fff" v-if="!showLegacy"
                  @click="showLegacy=1"></i>
                <i class="el-icon-caret-bottom cursor-pointer" style="font-size: 30px;color:#fff" v-if="showLegacy"
                  @click="showLegacy=0"></i>
              </div>
            </div>
          </section>
          <section class='slide-down-legacy'>
            <el-row :gutter="10">
              <el-col :xs="24" :sm="8" class="mb-3">
                <el-date-picker style="width: 100%" clearable v-model="dateRange" @change="getProjectList"
                  type="datetimerange" range-separator="~" start-placeholder="Start" end-placeholder="End"
                  value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-col>
              <el-col :xs="24" :sm="4" class="mb-3">
                <el-date-picker style="width: 100%" clearable v-model="searchParams.year" @change="getProjectList"
                  type="year" placeholder="Year" value-format="yyyy">
                </el-date-picker>
              </el-col>
              <el-col :xs="24" :sm="7" class="mb-3">
                <el-select clearable @clear="getProjectList" v-model="searchParams.area_category"
                  @change="getProjectList" placeholder="Areas of Opportunity Category" style="width: 100%;">
                  <el-option v-for="(v,i) in opportunityList" :value="v.value" :key="v.value" :label="v.label">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="5" class="mb-3">
                <el-select clearable @clear="getProjectList" v-model="searchParams.project_category"
                  @change="getProjectList" placeholder="Rise Pillars Category" style="width: 100%;">
                  <el-option v-for="(v,i) in categoryList" :value="v.value" :key="v.value" :label="v.label">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <OrganizationProject @getOrganizationParams="getOrganizationParams" />
              <el-col :xs="24" :sm="5" class="mb-3">
                <el-select clearable @clear="getProjectList" v-model="searchParams.is_has_project"
                  @change="getProjectList" style="width: 100%;" placeholder="RISE Project">
                  <el-option :value="1" label="Yes"></el-option>
                  <el-option :value="0" label="No"></el-option>
                </el-select>
              </el-col>
              <!-- <el-col :xs="24" :sm="5" class="mb-3">
                <el-select clearable @clear="getProjectList" v-model="searchParams.is_extended" @change="getProjectList"
                  style="width: 100%;" placeholder="Tab">
                  <el-option :value="1" label="Project Gallery"></el-option>
                  <el-option :value="0" label="Legacy Projects"></el-option>
                </el-select>
              </el-col> -->
            </el-row>
          </section>
          <b-row v-if="loading">
            <b-col v-for="(item, index) in 12" :key="index" md="6" lg="4">
              <el-skeleton animated>
                <template slot="template">
                  <iq-card body-class="p-0" style="border-radius: 5px;overflow: hidden;">
                    <el-skeleton-item variant="image" style="width: 100%; height: 140px;" />
                    <el-skeleton-item variant="text" style="width: 220px;height: 30px;margin: 16px 10px 8px;" />
                    <el-skeleton-item variant="text" style="width: 150px;height: 28.63px;margin: 6px 10px 13px" />
                  </iq-card>
                </template>
              </el-skeleton>
            </b-col>
          </b-row>
          <section class="Offline" v-if="projectList.length&&!loading">
            <b-row>
              <b-col v-for="(item, index) in projectList" :key="index" md="6" lg="4" class="cursor-pointer"
                @click="$router.push(`/projects-detail?id=${item.id}&page=${page}`)">
                <Prerecording :cardInfo="item" />
              </b-col>
            </b-row>
          </section>
          <section v-if="!projectList.length" class="d-flex justify-content-center"
            style="background:#fff;padding: 60px;padding-bottom: 80px;">
            <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" v-if="!loading" />
          </section>
        </div>
        <div v-if="projectList.length" class="d-flex justify-content-end container">
          <el-pagination :pager-count="5" background layout="prev, pager, next" :page-size="size"
            @current-change="handleCurrentChange" :current-page.sync="page" :total="total" hide-on-single-page>
          </el-pagination>
        </div>
      </tab-content-item>

      <tab-content-item :active="active == 'explore'" aria-labelled-by="prerecording-tab-two">
        <div class="container">
          <div class="pt-2">
            <iq-card style="min-height: 500px;">
              <div class="p-3 project_introduction">
                <section v-html="introduction"></section>
              </div>
            </iq-card>
          </div>
        </div>
      </tab-content-item>
    </tab-content>
    <CreateProjectDialog ref="child" :id='0' />

  </div>
</template>
<script>
  import FullBanner from '@/components/ComComponents/FullBanner'
  import Prerecording from './components/Prerecording'
  import CreateProjectDialog from './components/CreateProjectDialog'
  import OrganizationProject from '@/components/ComComponents/OrganizationProject'
  import { mapState } from "vuex";
  export default {
    mounted() { },
    components: {
      FullBanner,
      Prerecording,
      OrganizationProject,
      CreateProjectDialog
    },

    computed: {
      ...mapState('Public', {
        active: state => state.is_list,
        pageState: state => state.pageState,
      }),

    },

    data() {
      return {
        page: 1,
        size: 12,
        total: 0,
        loading: true,

        opportunityList: [
          { value: 1, label: 'Business and Entrepreneurship' },
          { value: 2, label: 'International Cooperation' },
          { value: 3, label: 'Community Impact' },
          { value: 4, label: 'Individual Development' }
        ],
        categoryList: [
          { value: 1, label: 'Preserving Mental Health' },
          { value: 2, label: 'Sustaining and Rebuilding Economies' },
          { value: 3, label: 'Workforce Motivation' }
        ],
        theme: 'JCI Projects',
        introduction: '',
        searchParams: {
          project_name: '',
          year: '',
          project_category: '',
          area_category: '',
          area_id: '',
          national_id: '',
          local_id: '',
          is_has_project: '',
        },
        dateRange: [],
        projectList: [],
        showMore: 0,
        showLegacy: 0,
        is_extended: 1
      }
    },
    watch: {
      showMore: {
        handler(newData) {
          var content = document.querySelector('.slide-down-content');
          if (newData) {
            content.classList.add('show');
          } else {
            content.classList.remove('show');
          }
        },
      },

      showLegacy: {
        handler(newData) {
          var legacy = document.querySelector('.slide-down-legacy');
          if (newData) {
            legacy.classList.add('show');
          } else {
            legacy.classList.remove('show');
          }
        },
      },

      active: {
        deep: true,
        handler(newData) {
          this.showMore = false
          this.showLegacy = false
          Object.keys(this.searchParams).forEach((key) => {
            if (Array.isArray(this.searchParams[key])) {
              this.searchParams[key] = []
            } else {
              this.searchParams[key] = ''
            }
          })
          if (newData == 'legacy') {
            this.is_extended = 0
            this.getProjectList()
          } else {
            this.is_extended = 1
            this.getProjectList()
          }
        }
      }
    },

    created() {
      this.page = this.pageState;
      this.getProjectRise()
      this.getProjectList()
    },

    methods: {
      getOrganizationParams(data) {
        let OrganData = JSON.parse(JSON.stringify(data))
        let { local, regional, national, area } = OrganData
        this.searchParams.area_id = area.id || 0
        this.searchParams.national_id = national.id || 0
        this.searchParams.local_id = local.id || 0
        this.getProjectList()
      },

      handleCurrentChange(page) {
        this.page = page
        this.getProjectList()
      },

      openDialog() {
        this.$refs.child.requestDialog = true
        this.$refs.child.clearFormLabelAlign()
        this.$refs.child.getProjectConfig()
      },

      // 项目Rise
      async getProjectRise() {
        let res = await this.$http.getProjectRise()
        if (res.status == 200) {
          this.theme = res.data.theme
          this.introduction = res.data.introduction
        }
      },

      // 获取项目列表
      async getProjectList() {
        this.dateRange = this.dateRange || []
        let [start, end] = this.dateRange
        this.loading = true
        let res = await this.$http.getProjectList({
          type: 1,
          submission_start: start,
          submission_end: end,
          per_page: this.size,
          page: this.page,
          ...this.searchParams,
          is_extended: this.is_extended
        })
        if (res.status == 200) {
          this.total = res.data.total
          this.projectList = res.data.data || []
        }
        setTimeout(() => {
          this.loading = false
        }, 0)
      },

    },

    beforeDestroy() {
      this.$store.commit("Public/CHANGE_LIST", 'gallery')
      this.$store.commit("Public/CHANGE_PAGE", 1)
    },
  }

</script>
<style lang="scss" scoped>
  .JCI_Projects {
    min-height: 100vh;

    ::v-deep .el-dialog__body {
      padding: 0;
    }

    .top_style {
      position: relative;
    }

    .slide-down-content {
      height: 0;
      overflow: hidden;
      transition: height 0.5s ease;
      /* 平滑过渡效果 */
    }

    .slide-down-content.show {
      height: 122px;
      /* 或你希望的任何高度 */
    }

    .slide-down-legacy {
      height: 0;
      overflow: hidden;
      transition: height 0.5s ease;
    }

    .slide-down-legacy.show {
      height: 122px;
    }

    @media (min-width: 600px) {
      .project_introduction {
        ::v-deep img {
          max-width: 100%;
        }
      }

      .option_btn {
        display: flex;
      }

      .project_introduction {
        ::v-deep img {
          max-width: 100%;
        }
      }

      .phone_btn {
        display: none;
      }
    }

    @media (max-width: 600px) {
      .slide-down-content.show {
        height: 430px;
        /* 或你希望的任何高度 */
      }

      .slide-down-legacy.show {
        height: 430px;
      }

      .project_introduction {
        ::v-deep img {
          max-height: 550px;
        }
      }

      .option_btn {
        display: none;
      }

      .phone_btn {
        display: block;
        position: absolute;
        right: 1.4rem;
        top: 1.2rem;
        font-size: 1.5rem;
      }

      .project_introduction {
        width: 100%;
        overflow: hidden;

        ::v-deep img {
          max-width: 100%;
        }

        ::v-deep p {
          overflow-y: auto;
          max-width: 100%;
        }
      }
    }

    .option_btn {
      position: absolute;
      /* display: flex; */
      right: 18px;
      top: 16px;

      .to_my_training {
        margin: 10px 0;
        cursor: pointer;
        border-bottom: 1px solid #50B5FF;
        color: #50B5FF;
        line-height: 18px;
      }
    }

    .headerContent {
      .content {
        padding-top: 28px;

        .searchBox {
          padding: 0 8px;
          position: relative;

          img {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }

        .selectBox {
          margin: 14px 0 50px;
        }
      }
    }
  }

  .tab-btn-my {
    display: flex;
    justify-content: space-between;
    align-items: center;

    #myTab-two {
      margin-bottom: 0;
    }

    .toMyMeeting {
      width: 130px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      border: 1px solid #50b5ff;
      background-color: #f5fbff;
      color: #50b5ff;
      cursor: pointer;

      >i {
        margin-left: 10px;
      }
    }
  }

  ::v-deep .el-date-editor--date .el-input__inner,
  ::v-deep .el-select .el-input__inner,
  ::v-deep .el-input__inner {
    height: 45px;
    border-radius: 11px;
  }
</style>